import * as React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import LogoImage from '../Header/LogoImage';
import LogoSrc from '../../images/logo.png';
import RegistrierungsButton from '../Button/registrierungsButton';
import { footerStyles } from './styles';

const ItemLink = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #545372;

  &:hover {
    color: #080b2d;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const ItemLinkextern = styled.a`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #545372;

  &:hover {
    color: #080b2d;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  const classes = footerStyles();

  return (
    <>
      <footer className={classes.footer} id="footer">
        <Grid container spacing={3}>
          <Grid item lg={3} md={6} xs={12}>
            {/* <Typography className={classes.header}>Unternehmen</Typography> */}
            <Box className={classes.image}>
              <LogoImage src={LogoSrc} />
            </Box>
            <Typography className={classes.contentBold}>{t('trans.adressCompany')}</Typography>
            <Typography className={classes.contentFooter}>{t('trans.adressStreet')}</Typography>
            <Typography className={classes.contentFooter}>{t('trans.adressLocation')}</Typography>
            <Typography className={classes.contentFooter}>{t('trans.adressCountry')}</Typography>
          </Grid>

          <Grid item lg={3} md={6} xs={12}>
            <Typography className={classes.header}>{t('trans.security')}</Typography>
            <Typography className={classes.contentFooter}>
              <ItemLink to="/privacy-policy">{t('trans.privacyPolicyPage')}</ItemLink>
            </Typography>
            {/* <Typography className={classes.contentFooter}>
              <ItemLink to="/agb">{t('trans.agb')}</ItemLink>
            </Typography> */}
            <Typography className={classes.contentFooter}>
              <ItemLink to="/legal-notice">{t('trans.imprint')}</ItemLink>
            </Typography>
          </Grid>

          <Grid item lg={3} md={6} xs={12}>
            <Typography className={classes.header}>{t('trans.subjects')}</Typography>
            <Typography className={classes.contentFooter}>
              <ItemLinkextern href="https://app.d-simple.com/register" target="_blank" rel="noreferrer">
                {t('trans.appDsimple')}
              </ItemLinkextern>
            </Typography>

            {/* <Typography className={classes.contentFooter}>
              <ItemLinkextern
                // href="https://wiki.d-simple.com/de/home"
                href={t('trans.wikiLink')}
                target="_blank"
                rel="noreferrer"
              >
                {t('trans.wikiLinkName')}

              </ItemLinkextern>
            </Typography> */}

            {/* <Typography className={classes.contentFooter}>
              <ItemLink to="/tutorials">Tutorials</ItemLink>
            </Typography> */}

            {/* <Typography className={classes.contentFooter}>
              <ItemLinkextern
                href="https://examples.d-simple.com/umo/view/instruction/6226640127bac50013484a92?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbmlzYXRpb25JZCI6IjYyMjY1YWVkMjdiYWM1MDAxMzQ4NDhmNCIsInR5cGUiOiJvcGVuLWluc3RydWN0aW9uIiwiaWF0IjoxNjQ2NjgzMTc2LCJleHAiOjE4MDQ0NzExNzZ9.z_3PXApLyw4oxq3ajOAy8Sx0fXh__4Knmz-wSoiDomA"
                target="_blank"
                rel="noreferrer"
              >
                {t('trans.rehearsalInstruction')}
              </ItemLinkextern>
            </Typography> */}

            <Typography className={classes.contentFooter}>
              <ItemLink to="/calculator">{t('trans.calculator')}</ItemLink>
            </Typography>
          </Grid>
          {/* <Grid item lg={6} md={6} xs={12}> */}
          <Grid item lg={3} md={6} xs={12}>
            <Typography className={classes.header}>{t('trans.serviceContact')}</Typography>
            <Typography className={classes.contentmail}>{t('trans.adressEmailSupport')}</Typography>
            <Typography className={classes.contentFooter}>
              <ItemLink to="/contact">{t('trans.contactform')}</ItemLink>
            </Typography>
            <Box mt={4}>
              <RegistrierungsButton />
            </Box>
          </Grid>
        </Grid>
      </footer>
      <Grid item sm={12} xs={12} className={classes.copyrightBox}>
        <Box className={classes.copyrightBox}>
          <Typography className={classes.copyrightContainer}>
            © {new Date().getFullYear()} Hirten Technologies
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
